<template>
  <v-container>
    <CircleListTable></CircleListTable>
  </v-container>
</template>

<script>
import CircleListTable from "@/components/office_components/CircleListTable";

export default {
  name: "CircleListpage",
  components: {CircleListTable},
  mounted() {
    document.title = 'Agrani Bank Legal Suit System | Circle List';
  }
}
</script>

<style scoped>

</style>
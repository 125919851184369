<template>
  <v-dialog v-model="dialog" max-width="30%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" small
              v-on="on">
        mdi-delete
      </v-icon>
    </template>
    <v-card class="warning justify-center">
      <v-card-title class="text-h5 justify-center"><span class="text-center">Are you sure you want to delete circle# {{
          this.circle.name
        }}?</span>
      </v-card-title>
      <v-card-text v-if="errors">
        <span class="red--text">*{{ errors }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog=false">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="submit_loading" text @click="delete_circle">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "delete_circle",
  props: ['circle'],
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    errors: null,
  }),
  methods: {
    async delete_circle() {
      this.axios_conf
      await axios.delete(`user/circle_office/${this.circle.code}/delete`)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            this.errors = error.response.data.status
            this.$store.commit('setJWT', error.response.data.key)
          })
    },
  },
  watch: {
    passed_circle(val) {
      return this.circle = val
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs"
             v-on="on"
      >
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-icon v-if="!big_btn" v-bind="attrs" small
              v-on="on" class="mx-4">
        {{ activator_icon }}
      </v-icon>
    </template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="code" name="Circle Code" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Code*"
                      hint="Circle Code"
                      required
                      autocomplete="nope"
                      v-model="circle.code"
                      maxlength="20"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Circle Name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Circle Name*"
                      hint="Circle Name"
                      required
                      autocomplete="nope"
                      v-model="circle.name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_new_circles",
  props: ['passed_circle', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    circle: {
      code: '',
      name: ''
    },
    default_circle: {
      code: '',
      name: ''
    }
  }),
  methods: {
    async add_new_circle() {
      this.axios_conf
      let data = {code: this.circle.code, name: this.circle.name}
      await axios.post('user/circle_office', data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                code: error.response.data.errors.code,
                name: error.response.data.errors.name,
              });
            }
          })
    },
    async edit_circle() {
      this.axios_conf
      let data = {code: this.circle.code, name: this.circle.name}
      await axios.patch(`user/circle_office/${this.circle.code}/update`, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            // this.$refs.vform.reset()
            // this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                code: error.response.data.errors.code,
                name: error.response.data.errors.name,
              });
            }
          })
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_circle()
      } else if (this.action === 'edit') {
        this.edit_circle()
      }
      this.submit_loading = false
    }
  },
  watch: {
    passed_circle(val) {
      console.log(val)
      this.circle = JSON.parse(JSON.stringify(this.passed_circle))
    },
  },
  mounted() {
    if (this.passed_circle) {
      this.circle = JSON.parse(JSON.stringify(this.passed_circle))
    }
  }
}
</script>

<style scoped>

</style>
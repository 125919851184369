<template>
  <v-container v-if="has_view_permission">
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <add_new_circles v-if="add_circle_button" activator_name="Add New Circle Office"
                         form_title="Add New Circle Office"
                         activator_icon="mdi-plus-thick" action="add" @form_status="get_circles"
                         big_btn="true"></add_new_circles>
        <v-btn :loading="reload_btn" @click="get_circles" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="circles"
          sort-by="code"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            Circle Office List
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <!--        <template-->
        <!--            v-slot:body="{ items }"-->
        <!--        >-->
        <!--          <tbody>-->
        <!--          <tr-->
        <!--              v-for="item in items"-->
        <!--              :key="item.code"-->
        <!--          >-->
        <!--            <td class="text-center">{{ item.code }}</td>-->
        <!--            <td class="text-center">{{ item.name }}</td>-->
        <!--            <td class="text-center">-->
        <!--              <add_new_circles v-if="edit_button"-->
        <!--                               form_title="Edit Circle Office" :passed_circle="item"-->
        <!--                               activator_icon="mdi-pencil" action="edit" @form_status="get_circles"-->
        <!--              ></add_new_circles>-->
        <!--              &lt;!&ndash;              <edit_circles v-if="edit_button" form_title="Edit Circle"&ndash;&gt;-->
        <!--              &lt;!&ndash;                            activator_icon="mdi-pencil" action="edit" :passed_circle="item"&ndash;&gt;-->
        <!--              &lt;!&ndash;                            @form_status="get_circles"></edit_circles>&ndash;&gt;-->
        <!--              <delete_circle v-if="delete_button" @form_status="get_circles" :circle="item"></delete_circle>-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--          </tbody>-->
        <!--        </template>-->

        <template v-slot:item="{item}">
          <tr>
            <td class="text-center">
              {{ item.code }}
            </td>
            <td class="text-center">
              {{ item.name }}
            </td>
            <td class="text-center">
              <add_new_circles v-if="edit_button"
                               form_title="Edit Circle Office" :passed_circle="item"
                               activator_icon="mdi-pencil" action="edit" @form_status="get_circles"
              ></add_new_circles>
              <delete_circle v-if="delete_button" @form_status="get_circles" :circle="item"></delete_circle>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import add_new_circles from "@/components/office_components/cirlces_sub_components/add_new_circles";
// import edit_circles from "@/components/office_components/cirlces_sub_components/edit_circles";
import delete_circle from "@/components/office_components/cirlces_sub_components/delete_circle";


export default {
  name: "CircleListTable",
  components: {add_new_circles, delete_circle},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('users.view_circleoffice')
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('users.change_circleoffice')
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('users.delete_circleoffice')
    },
    add_circle_button() {
      return this.$store.getters.permissionCheckers('users.add_circleoffice')
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [{
      text: 'Circle Code',
      align: 'center',
      filterable: true,
      value: 'code',
    },
      {
        text: 'Circle Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'code',
      },
    ],
    circles: [],
    search: '',
  }),
  methods: {
    async get_circles() {
      this.axios_conf
      this.reload_btn = true
      this.table_loading = true
      await axios.get("user/circle_office").then((resp) => {
        // console.log(resp)
        this.circles = resp.data.circles
        this.$store.commit('setJWT', resp.data.key)
      })
      this.reload_btn = false
      this.table_loading = false
    },
  },
  mounted() {
    this.get_circles();
  }
}
</script>

<style scoped>
tbody tr:hover {
  background: #82B1FF !important;
}
</style>